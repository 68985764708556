body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.music-bar {
  width: 400px;
  margin: 0 20px;
  display: flex;
  align-items: center;

  label {
    margin-right: 10px;
  }
}

#recorder_play_container {
  width: 320px;
}