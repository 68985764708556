* {
  box-sizing: border-box;
}

#root {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 480px) {
  #root {
    width: 99%;
  }
}

hr {
  margin-top: 3rem;
}

.joinroom-config-item {
  width: 95%;
  max-width: 400px;
  margin-bottom: 0.5rem;
  display: grid;
  grid-template-columns: 150px 1fr;
  justify-content: center;
}

.joinroom-config-item > input, select {
	width: 100%;
	margin: 0
}

.local-audio-file, .online-audio, .online-video {
  width: 180px;
  margin-right: 8px;
}

.input-label {
  display: inline-block;
  width: 120px;
  margin-right: 16px;
}

.input-item {
  width: 180px;
}

.meta_container {
  position: fixed;
  bottom: 16px;
  display: flex;
  flex-wrap: wrap;
  font-size: smaller;
  color: gray;
}

.meta_container>p {
  margin: 0 12px 0 12px;
}

label, input, button, span {
  display: inline-block;
  margin: 2px 5px 2px 2px;
}

.narrow-text-input {
  width: 60px;
}

select {
  max-width: 300px;
}
.loading-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-wrapper .loading-mask {
  z-index: 9999;
  background-color: #efefef;
  opacity: 0.2;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.loading-wrapper .loading-content {
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  background: #424242;
  border-radius: 4px;
}

.loading-icon {
  padding: 0;
  margin: 0;
  width: 40px;
  height: 40px;
  animation: progress-circular-rotate 1.4s linear infinite;
}

.loading-icon circle {
  animation: progress-circular-dash 1.4s ease-in-out infinite;
  stroke-dasharray: 80px, 200px;
  stroke-dashoffset: 0px;
  stroke: currentColor;
}

.loading-icon svg {
  color: #34aadc;
}

.loading-text {
  margin: 24px;
  color: rgba(255, 255, 255, 0.7);
}

@keyframes progress-circular-rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes progress-circular-dash {
  0% {
    stroke-dasharray: 1px, 200px;
    stroke-dashoffset: 0px;
  }
  50% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -15px;
  }
  100% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -120px;
  }
}

.track-container {
  position: relative;
  width: 320px;
  height: 240px;
  background: #000;
  display: inline-block;
}

.track__player {
  width: 100%;
  height: 100%;
}

.track__id {
  font-size: 13px;
  position: absolute;
  bottom: 4px;
  right: 4px;
  color: rgba(255, 255, 255, 0.9);
  text-shadow: 0 0 10px rgba(0, 0, 0, 1);
  text-align: right;
  width: 100%;
  z-index: 9;
}

.track__info {
  position: absolute;
  padding-left: 2px;
  padding-top: 2px;
  width: 40%;
  height: 100%;
  z-index: 6;
  top: 0px;
  left: 0px;
  white-space: pre;
  color: rgba(255, 255, 255, 0.9);
  text-shadow: 0 0 20px rgba(0, 0, 0, 1);
  font-size: 10px;
  background-color: rgb(150, 150, 150, 0.5);
}

.track__info__item {
  margin-bottom: 5px;
}

.track__control {
  position: absolute;
  padding-right: 2px;
  padding-top: 2px;
  width: 60%;
  top: 0;
  right: 0;
  color: rgba(255, 255, 255, 0.9);
  z-index: 9;
  font-size: 10px;
}

.track__control button {
  font-size: 10px;
  padding: 1px 3px;
}

.track__audio {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
}


.user-tracks-player {
  width: 100%;
  overflow-x: scroll;
}
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.music-bar {
  width: 400px;
  margin: 0 20px;
  display: flex;
  align-items: center;

  label {
    margin-right: 10px;
  }
}

#recorder_play_container {
  width: 320px;
}
