.loading-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-wrapper .loading-mask {
  z-index: 9999;
  background-color: #efefef;
  opacity: 0.2;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.loading-wrapper .loading-content {
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  background: #424242;
  border-radius: 4px;
}

.loading-icon {
  padding: 0;
  margin: 0;
  width: 40px;
  height: 40px;
  animation: progress-circular-rotate 1.4s linear infinite;
}

.loading-icon circle {
  animation: progress-circular-dash 1.4s ease-in-out infinite;
  stroke-dasharray: 80px, 200px;
  stroke-dashoffset: 0px;
  stroke: currentColor;
}

.loading-icon svg {
  color: #34aadc;
}

.loading-text {
  margin: 24px;
  color: rgba(255, 255, 255, 0.7);
}

@keyframes progress-circular-rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes progress-circular-dash {
  0% {
    stroke-dasharray: 1px, 200px;
    stroke-dashoffset: 0px;
  }
  50% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -15px;
  }
  100% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -120px;
  }
}
