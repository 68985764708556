.track-container {
  position: relative;
  width: 320px;
  height: 240px;
  background: #000;
  display: inline-block;
}

.track__player {
  width: 100%;
  height: 100%;
}

.track__id {
  font-size: 13px;
  position: absolute;
  bottom: 4px;
  right: 4px;
  color: rgba(255, 255, 255, 0.9);
  text-shadow: 0 0 10px rgba(0, 0, 0, 1);
  text-align: right;
  width: 100%;
  z-index: 9;
}

.track__info {
  position: absolute;
  padding-left: 2px;
  padding-top: 2px;
  width: 40%;
  height: 100%;
  z-index: 6;
  top: 0px;
  left: 0px;
  white-space: pre;
  color: rgba(255, 255, 255, 0.9);
  text-shadow: 0 0 20px rgba(0, 0, 0, 1);
  font-size: 10px;
  background-color: rgb(150, 150, 150, 0.5);
}

.track__info__item {
  margin-bottom: 5px;
}

.track__control {
  position: absolute;
  padding-right: 2px;
  padding-top: 2px;
  width: 60%;
  top: 0;
  right: 0;
  color: rgba(255, 255, 255, 0.9);
  z-index: 9;
  font-size: 10px;
}

.track__control button {
  font-size: 10px;
  padding: 1px 3px;
}

.track__audio {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
}
