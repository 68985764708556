* {
  box-sizing: border-box;
}

#root {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 480px) {
  #root {
    width: 99%;
  }
}

hr {
  margin-top: 3rem;
}

.joinroom-config-item {
  width: 95%;
  max-width: 400px;
  margin-bottom: 0.5rem;
  display: grid;
  grid-template-columns: 150px 1fr;
  justify-content: center;
}

.joinroom-config-item > input, select {
	width: 100%;
	margin: 0
}

.local-audio-file, .online-audio, .online-video {
  width: 180px;
  margin-right: 8px;
}

.input-label {
  display: inline-block;
  width: 120px;
  margin-right: 16px;
}

.input-item {
  width: 180px;
}

.meta_container {
  position: fixed;
  bottom: 16px;
  display: flex;
  flex-wrap: wrap;
  font-size: smaller;
  color: gray;
}

.meta_container>p {
  margin: 0 12px 0 12px;
}

label, input, button, span {
  display: inline-block;
  margin: 2px 5px 2px 2px;
}

.narrow-text-input {
  width: 60px;
}

select {
  max-width: 300px;
}